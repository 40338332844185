<template>
  <v-card>
    <v-card-text v-if="clusterId">
      <v-data-table
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Diğer Gelir Tanımları"
            icon="mdi mdi-cash-100"
            :add-route="
              can('edit-other-income-type')
                ? { name: 'other-definitions.other-incomes.create' }
                : null
            "
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            :hide-edit="!can('edit-other-income-type')"
            :edit-enabled="selectedItems.length === 1"
            :search.sync="search.query"
            @click:edit="handleEditClick"
            :show-delete="can('delete-other-income-type')"
            :delete-enabled="selectedItems.length === 1"
            @click:delete="handleDeleteClick"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            class="btn btn-icon btn-sm btn-clean"
            :to="{
              name: 'other-definitions.other-incomes.edit',
              params: { id: item.id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.name="{ item }">
          <router-link
            :to="{
              name: 'other-definitions.other-incomes.show',
              params: { id: item.id },
            }"
          >
            {{ item.name }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.provider="{ item }">
          <router-link
            :to="{
              name: 'definitions.cluster-providers.show',
              params: { id: item.provider_id },
            }"
          >
            {{ item.provider }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_active="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-text v-else>
      Listeyi görmek için üst menüden bir toplu yaşam alanı seçin.
    </v-card-text>
    <rs-confirm
      ref="confirmDelete"
      :loading="isLoading"
      @confirmed="handleDelete"
      @cancelled="$refs.confirmDelete.hide()"
    />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasPermissions } from "@/view/mixins";

export default {
  mixins: [filtersToURL, hasPermissions],
  computed: {
    ...mapGetters(["clusterId"]),
  },
  data() {
    return {
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
          hide: () => !this.can("edit-other-income-type"),
        },
        {
          text: "Gelir Adı",
          value: "name",
          searchable: "text",
        },
        {
          text: "Varsayılan Tedarikçi",
          value: "provider",
          searchable: "text",
        },
        {
          text: this.$t("labels.status"),
          value: "is_active",
          searchable: "isActive",
          align: "center",
        },
      ],
    };
  },
  methods: {
    handleDelete() {
      this.isLoading = true;

      this.$api
        .delete(`types/expense-types/${this.selectedItems[0].id}`)
        .then(() => {
          this.$toast.success("Silindi");
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.$nextTick(() => this.loadList());
        });
      this.$refs.confirmDelete.hide();
    },
    handleDeleteClick() {
      this.$refs.confirmDelete.show(
        `${this.selectedItems[0].name} diğer geliri silmek istediğinizden emin misiniz?`
      );
    },
    handleEditClick() {
      if (this.selectedItems.length !== 1) {
        return false;
      }

      this.$router.push({
        name: "other-definitions.other-incomes.edit",
        params: {
          id: this.selectedItems[0].id,
        },
      });
    },
    loadList() {
      if (this.isLoading || !this.clusterId) {
        return;
      }

      this.isLoading = true;
      this.list = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.cluster_id = this.clusterId;
      params.sign = -1;

      this.$api
        .query("types/expense-types", { params })
        .then((response) => {
          const list = [];

          if (response.data && response.data.data) {
            for (const item of response.data.data) {
              list.push(item);
            }
          }

          this.list = list;

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }),
      deep: true,
    },
    clusterId() {
      this.loadList();
    },
  },
};
</script>
